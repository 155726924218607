import React from 'react';
import {
    FC,
    useEffect,
    useState,
    createContext,
    useContext,
    useReducer,
    useCallback
} from "react"
import DataItem from '../entities/DataItem';
import { getNationList, getOutgoingItem, updateOutgoingItem, submitOutgoingItem } from '../DataAccess';
import LanguageTable from './tables/language-table';
import SelfEvaluationTable from './tables/self-evaluation-table';
import UniversitiesTable from './tables/university-table';
import DropDownSelect from './dropDown'
import Textbox from './inputText'
import RadioButton from './inputRadio'
import SessionContext from "../entities/SessionContext"
import OutgoingItem from '../entities/OutgoingItem';
import OutgoingSaveRequest from '../entities/OutgoingSaveRequest';
import LanguageItem from '../entities/LanguageItem';
import AdressItem from '../entities/AdressItem';
import DocumentItem from '../entities/DocumentItem';
import UniversityItem from '../entities/UniversityItem';
import { Button } from '@progress/kendo-react-buttons';
import ReactLoading from 'react-loading';
import { DsgvoDialog } from './dialogs/dsgvo-dialog';
import { SubmitDialog } from './dialogs/submit-dialog';
import { ValidationDialog } from './dialogs/validation-dialog';
import LoadingAnimation from '../helper/loading.component';
import $ from 'jquery';

interface State {
    outgoingItem: OutgoingItem
    TempCVFilename: string
    TempCVFiletype: string
    submitted: boolean
    submittedJustNow: boolean
    submitDialogVisible: boolean
    dsgvoDialogVisible: boolean
    validationErrors: string[]
    validationDialogVisible: boolean
    applicationDeadline: string
    isSaving: boolean
    ready: boolean
    authorized: boolean
    submitVisible: boolean
}

type Action =
    | {
        type: "setPhoneNumber"
        payload: string
    }
    | {
        type: "setPassportNumber"
        payload: string
    }
    | {
        type: "setStrasse"
        payload: string
    }
    | {
        type: "setPlz"
        payload: string
    }
    | {
        type: "setOrt"
        payload: string
    }
    | {
        type: "setNationID"
        payload: number
    }
    | {
        type: "setECFirstName"
        payload: string
    }
    | {
        type: "setECLastName"
        payload: string
    }
    | {
        type: "setECRelationship"
        payload: string
    }
    | {
        type: "setECEmail"
        payload: string
    }
    | {
        type: "setECPhone"
        payload: string
    }
    | {
        type: "setECStrasse"
        payload: string
    }
    | {
        type: "setECPlz"
        payload: string
    }
    | {
        type: "setECOrt"
        payload: string
    }
    | {
        type: "setECNationID"
        payload: number
    }
    | {
        type: "setMotivation"
        payload: string
    }
    | {
        type: "setAllowForwardData"
        payload: boolean
    }
    | {
        type: "setMotherTongueID"
        payload: number
    }
    | {
        type: "initial"
        payload: OutgoingItem
    }
    | {
        type: "setUniUniversity"
        payload: {
            preference: number
            university: string
        }
    }
    | {
        type: "setUniNationID"
        payload: {
            preference: number
            nationID: number | null
        }
    }
    | {
        type: "setUniUniversityID"
        payload: {
            preference: number
            universityID: number | null
        }
    }
    | {
        type: "setUniDoubleDegree"
        payload: {
            preference: number
            doubledegree: boolean
        }
    }
    | {
        type: "setChosenLanguagesLanguageID"
        payload: {
            index: number
            itemID: number
            languageID: number
        }
    }
    | {
        type: "setChosenLanguagesEvaluation"
        payload: {
            index: number
            itemID: number
            evaluation: string
        }
    }
    | {
        type: "setChosenLanguagesCerificate"
        payload: {
            index: number
            itemID: number
            certificate: {
                filename: string
                filetype: string
                content: string
            }
        }
    }
    | {
        type: "setCV"
        payload: DocumentItem
    }
    | {
        type: "setTempCVFilename"
        payload: string
    }
    | {
        type: "setTempCVFiletype"
        payload: string
    }
    | {
        type: "setSubmitDialogVisible"
        payload: boolean
    }
    | {
        type: "setDsgvoDialogVisible"
        payload: boolean
    }
    | {
        type: "setValidationErrors"
        payload: string[]
    }
    | {
        type: "setValidationDialogVisible"
        payload: boolean
    }
    | {
        type: "setApplicationDeadline"
        payload: string
    }
    | {
        type: "setIsSaving"
        payload: boolean
    }
    | {
        type: "setReady"
        payload: boolean
    }
    | {
        type: "setAuthorized"
        payload: boolean
    }
    | {
        type: "setSubmitted"
        payload: boolean
    }
    | {
        type: "setSubmittedJustNow"
        payload: boolean
    }
    | {
        type: "setSubmitVisible"
        payload: boolean
    }

const outgoingReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "setSubmitVisible":
            return {
                ...state,
                submitVisible: action.payload
            }
        case "setSubmittedJustNow":
            return {
                ...state,
                submittedJustNow: action.payload
            }
        case "setSubmitted":
            return {
                ...state,
                submitted: action.payload
            }
        case "setAuthorized":
            return {
                ...state,
                authorized: action.payload
            }
        case "setReady":
            return {
                ...state,
                ready: action.payload
            }
        case "setApplicationDeadline":
            return {
                ...state,
                applicationDeadline: action.payload
            }
        case "setIsSaving":
            return {
                ...state,
                isSaving: action.payload
            }
        case "setSubmitDialogVisible":
            return {
                ...state,
                submitDialogVisible: action.payload
            }
        case "setDsgvoDialogVisible":
            return {
                ...state,
                dsgvoDialogVisible: action.payload
            }
        case "setValidationDialogVisible":
            return {
                ...state,
                validationDialogVisible: action.payload
            }
        case "setValidationErrors":
            return {
                ...state,
                validationErrors: action.payload
            }
        case "setTempCVFilename":
            return {
                ...state,
                TempCVFilename: action.payload
            }
        case "setTempCVFiletype":
            return {
                ...state,
                TempCVFiletype: action.payload
            }
        case "initial":
            return {
                ...state,
                outgoingItem: action.payload
            }
        case "setUniUniversity":
            const uniItem = state.outgoingItem.Universities.find(f => f.Preference === action.payload.preference)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Universities:
                        [...state.outgoingItem.Universities.filter(f => f.Preference !== action.payload.preference),
                        uniItem
                            ? {
                                ...uniItem,
                                University: action.payload.university
                            }
                            : {
                                University: action.payload.university,
                                Preference: action.payload.preference,
                                UniversityID: null,
                                DoubleDegree: false,
                                NationID: null
                            }]
                }
            }
        case "setUniUniversityID":
            const uniItem1 = state.outgoingItem.Universities.find(f => f.Preference === action.payload.preference)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Universities:
                        [...state.outgoingItem.Universities.filter(f => f.Preference !== action.payload.preference),
                        uniItem1
                            ? {
                                ...uniItem1,
                                UniversityID: action.payload.universityID
                            }
                            : {
                                University: "",
                                Preference: action.payload.preference,
                                UniversityID: action.payload.universityID,
                                DoubleDegree: false,
                                NationID: null
                            }]
                }
            }

        case "setUniNationID":
            const uniItem2 = state.outgoingItem.Universities.find(f => f.Preference === action.payload.preference)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Universities:
                        [...state.outgoingItem.Universities.filter(f => f.Preference !== action.payload.preference),
                        uniItem2
                            ? {
                                ...uniItem2,
                                NationID: action.payload.nationID
                            }
                            : {
                                NationID: action.payload.nationID,
                                Preference: action.payload.preference,
                                UniversityID: null,
                                DoubleDegree: false,
                                University: ""
                            }]
                }
            }
        case "setUniDoubleDegree":
            const uniItem3 = state.outgoingItem.Universities.find(f => f.Preference === action.payload.preference)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    Universities:
                        [...state.outgoingItem.Universities.filter(f => f.Preference !== action.payload.preference),
                        uniItem3
                            ? {
                                ...uniItem3,
                                DoubleDegree: action.payload.doubledegree
                            }
                            : {
                                NationID: null,
                                Preference: action.payload.preference,
                                UniversityID: null,
                                DoubleDegree: action.payload.doubledegree,
                                University: ""
                            }]
                }
            }
        case "setChosenLanguagesLanguageID":
            const languageItem = state.outgoingItem.OtherLanguages.find(f => f.Index === action.payload.index) //?? state.outgoingItem.OtherLanguages.find((f, i) => i === action.payload.index)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    OtherLanguages:
                        [...state.outgoingItem.OtherLanguages.filter(f => f.Index !== action.payload.index),
                        languageItem
                            ? {
                                ...languageItem,
                                LanguageID: action.payload.languageID,
                                Index: action.payload.index
                            }
                            : {
                                LanguageID: action.payload.languageID,
                                ItemId: 0,
                                Certificate: null,
                                SelfEvaluation: "",
                                Index: action.payload.index
                            }]
                }
            }
        case "setChosenLanguagesEvaluation":
            const languageItemEval = state.outgoingItem.OtherLanguages.find(f => f.Index === action.payload.index) //?? state.outgoingItem.OtherLanguages.find((f, i) => i === action.payload.index)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    OtherLanguages:
                        [...state.outgoingItem.OtherLanguages.filter(f => f.Index !== action.payload.index),
                        languageItemEval
                            ? {
                                ...languageItemEval,
                                SelfEvaluation: action.payload.evaluation,
                                Index: action.payload.index
                            }
                            : {
                                LanguageID: 0,
                                ItemId: 0,
                                Certificate: null,
                                SelfEvaluation: action.payload.evaluation,
                                Index: action.payload.index
                            }]
                }
            }
        case "setChosenLanguagesCerificate":
            const languageItemCert = state.outgoingItem.OtherLanguages.find(f => f.Index === action.payload.index) //?? state.outgoingItem.OtherLanguages.find((f, i) => i === action.payload.index)

            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    OtherLanguages:
                        [...state.outgoingItem.OtherLanguages.filter(f => f.Index !== action.payload.index),
                        languageItemCert
                            ? {
                                ...languageItemCert,
                                Index: action.payload.index,
                                Certificate: {
                                    FileName: action.payload.certificate.filename,
                                    FileType: action.payload.certificate.filetype,
                                    Content: action.payload.certificate.content
                                }
                            }
                            : {
                                LanguageID: 0,
                                ItemId: 0,
                                Index: action.payload.index,
                                Certificate: {
                                    FileName: action.payload.certificate.filename,
                                    FileType: action.payload.certificate.filetype,
                                    Content: action.payload.certificate.content
                                },
                                SelfEvaluation: ""
                            }]
                }
            }
        case "setMotherTongueID":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, MotherTongueID: action.payload }
            }
        case "setPhoneNumber":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, PhoneNumber: action.payload }
            }
        case "setPassportNumber":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, PassportNumber: action.payload }
            }
        case "setStrasse":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    CurrentAddress: {
                        ...state.outgoingItem.CurrentAddress,
                        Strasse: action.payload
                    }
                }
            }
        case "setPlz":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    CurrentAddress: {
                        ...state.outgoingItem.CurrentAddress,
                        Plz: action.payload
                    }
                }
            }
        case "setOrt":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    CurrentAddress: {
                        ...state.outgoingItem.CurrentAddress,
                        Ort: action.payload
                    }
                }
            }
        case "setNationID":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    CurrentAddress: {
                        ...state.outgoingItem.CurrentAddress,
                        nationID: action.payload
                    }
                }
            }
        case "setECFirstName":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, ECFirstName: action.payload }
            }
        case "setECLastName":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, ECLastName: action.payload }
            }
        case "setECRelationship":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, ECRelationship: action.payload }
            }
        case "setECEmail":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, ECEmail: action.payload }
            }
        case "setECPhone":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, ECPhone: action.payload }
            }
        case "setECStrasse":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    ECAddress: {
                        ...state.outgoingItem.ECAddress,
                        Strasse: action.payload
                    }
                }
            }
        case "setECPlz":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    ECAddress: {
                        ...state.outgoingItem.ECAddress,
                        Plz: action.payload
                    }
                }
            }
        case "setECOrt":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    ECAddress: {
                        ...state.outgoingItem.ECAddress,
                        Ort: action.payload
                    }
                }
            }
        case "setECNationID":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    ECAddress: {
                        ...state.outgoingItem.ECAddress,
                        nationID: action.payload
                    }
                }
            }
        case "setMotivation":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, Motivation: action.payload }
            }
        case "setAllowForwardData":
            return {
                ...state,
                outgoingItem: { ...state.outgoingItem, allowForwardData: action.payload }
            }
        case "setCV":
            return {
                ...state,
                outgoingItem: {
                    ...state.outgoingItem,
                    CV: {
                        ...state.outgoingItem.CV,
                        FileName: action.payload.FileName,
                        FileType: action.payload.FileType,
                        Content: action.payload.Content
                    }
                }
            }
        default:
            return {
                ...state
            }
    }
}

interface IStateContext {
    state: State
    dispatch: React.Dispatch<Action>
}

const intialState = {
    outgoingItem: {
        Title: "",
        FirstName: "",
        LastName: "",
        Gender: "",
        StudentID: "",
        StudentGuid: "",
        DegreeProgram: "",
        StudiengangID: 0,
        IsMaster: false,
        DateOfBirth: "",
        strDateOfBirth: "",
        PlaceOfBirth: "",
        CountryOfBirthID: null,
        Nationality: "",
        PassportNumber: "",
        Email: "",
        PhoneNumber: "",
        SemesterNr: 0,
        BewerbungsSemester: "",
        Deadline: "",
        CountryOfBirth: "",
        ECFirstName: "",
        ECLastName: "",
        ECRelationship: "",
        ECEmail: "",
        ECPhone: "",
        MotherTongueID: null,
        Motivation: "",
        FullName: "",
        CurrentAddress: {
            Plz: "",
            Ort: "",
            Strasse: "",
            nationID: 0
        } as AdressItem,
        ECAddress: {
            Plz: "",
            Ort: "",
            Strasse: "",
            nationID: 0
        } as AdressItem,
        allowForwardData: false,
        OtherLanguages: [{ ItemId: 0, LanguageID: -1, SelfEvaluation: "", Certificate: null, Index: 0 } as LanguageItem] as LanguageItem[],
        CV: {
            FileName: "",
            FileType: "",
            Content: ""
        } as DocumentItem,
        Universities: [{ UniversityID: null, University: "", Preference: 1, DoubleDegree: false, NationID: null } as UniversityItem] as UniversityItem[]
    } as OutgoingItem,
    TempCVFilename: "",
    TempCVFiletype: "",
    validationDialogVisible: false,
    dsgvoDialogVisible: false,
    submitDialogVisible: false,
    validationErrors: [""],
    isSaving: false,
    ready: false,
    authorized: false,
    submitVisible: false
} as State

export const ParentContext = createContext<IStateContext>({ state: intialState, dispatch: () => null });

const Registration: FC = () => {
    const { oAuthToken, studentGuid } = useContext(SessionContext)
    const [nationList, setNationList] = useState([] as DataItem[])
    const [motivationMessage, setMotivationMessage] = useState("")
    const [state, dispatch] = useReducer(outgoingReducer, intialState)
    useEffect(() => {
        (async () => {
            const nations = await getNationList(oAuthToken)
            if (nations)
                setNationList(nations)
        })()
    }, [oAuthToken])

    useEffect(() => {
        (async () => {
            const student = await getOutgoingItem(oAuthToken, studentGuid)
            if (student) {
                let date = new Date(student.Deadline)
                date.setHours(23, 59, 59)
                if (date < new Date()) {
                    dispatch({ type: "setReady", payload: true })
                    dispatch({ type: "setApplicationDeadline", payload: date.toLocaleDateString() })
                    return
                }
                else {
                    dispatch({ type: "initial", payload: student })
                    dispatch({ type: "setReady", payload: true })
                    dispatch({ type: "setAuthorized", payload: true })
                    dispatch({ type: "setSubmitted", payload: student.Status.StatusID !== 1 && student.Status.StatusID !== 5 })
                }
            }
        })()
    }, [oAuthToken, studentGuid])

    let fileReader: FileReader;
    let tempFileName: string = "";
    let tempFileTyp: string = "";
    const handleFileRead = () => {
        let content = ""
        if (typeof fileReader.result === "string") {
            content = fileReader.result.split(",")[1]
        }
        if (content) {
            dispatch({ type: "setCV", payload: { FileName: tempFileName, FileType: tempFileTyp, Content: content.toString() } })
        }
    };

    const cvChanged = (selectorFiles: FileList | null) => {
        if (selectorFiles != null) {
            let file = selectorFiles[0]
            /*  dispatch({ type: "setTempCVFilename", payload: file.name })
             dispatch({ type: "setTempCVFiletype", payload: file.type }) */
            tempFileName = file.name
            tempFileTyp = file.type
            fileReader = new FileReader()
            fileReader.onloadend = handleFileRead
            fileReader.readAsDataURL(file)
        }
    }

    const checkMotivation = () => {
        let elem = document.getElementById("motivation") as HTMLTextAreaElement
        let chars = elem.value.trim().length
        let msg = `${chars} character` + (chars !== 1 ? 's' : '')
        dispatch({ type: "setMotivation", payload: elem.value.trim() })
        setMotivationMessage(msg.toString())
    }

    const getCookie = (cname: string): string => {
        var name = cname + "="
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    const getThisStudentsCookieName = () => {
        return "dsgvo" + studentGuid
    }

    const rememberDsgvo = (event: any) => {
        let accepted = event.target.checked
        let expires = new Date()
        if (accepted) {
            //when the dsgvo is accepted, set a cookie to expire in 1 month
            expires.setMonth(expires.getMonth() + 1)
        } else {
            //else delete the cookie by setting date in past
            expires.setMonth(expires.getMonth() - 1)
        }
        document.cookie = `${getThisStudentsCookieName()}=true; expires=${expires.toUTCString()}; path=/`;
    }

    const ckStornoChanged = (event: any) => {
        let accepted = event.target.checked
        dispatch({ type: "setSubmitVisible", payload: accepted })
    }

    const toggleDsgvoDialogVisibility = useCallback(() => {
        dispatch({ type: "setDsgvoDialogVisible", payload: !state.dsgvoDialogVisible })
    }, [state.dsgvoDialogVisible])

    const toggleValidationDialogVisibility = useCallback(() => {
        dispatch({ type: "setValidationDialogVisible", payload: !state.validationDialogVisible })
    }, [state.validationDialogVisible])

    const toggleSubmitDialogVisibility = useCallback(() => {
        dispatch({ type: "setSubmitDialogVisible", payload: !state.submitDialogVisible })
    }, [state.submitDialogVisible])

    const dsgvoChecked = useCallback((): boolean => {
        return (document.getElementById("dsgvo") as HTMLInputElement).checked
    }, [])

    const showSaveStatus = useCallback((success: boolean, msg?: string) => {
        let popup = $("#popup-panel")
        let content = $("#popup-content")
        if (success) {
            content.removeClass("alert-danger")
            content.addClass("alert-success")
            content.html("Saved Successfully!")
        } else {
            content.removeClass("alert-success")
            content.addClass("alert-danger")
            content.html("Failed to Save")
        }
        popup.show()
        setTimeout(() => hideSaveStatus(), 2000)
    }, [])

    const hideSaveStatus = () => {
        let popup = $("#popup-panel")
        popup.fadeOut(800)
    }

    const isValid = useCallback((): boolean => {
        let valid = true
        let errors = []
        if (!state.outgoingItem.PassportNumber) {
            valid = false
            errors.push("Passport Number")
        }
        if (!state.outgoingItem.PhoneNumber) {
            valid = false
            errors.push("Applicant Phone Number")
        }
        if (!state.outgoingItem.CurrentAddress.nationID) {
            valid = false
            errors.push("Applicant Country")
        }
        if (!state.outgoingItem.CurrentAddress.Ort) {
            valid = false
            errors.push("Applicant City")
        }
        if (!state.outgoingItem.CurrentAddress.Strasse) {
            valid = false
            errors.push("Applicant Street")
        }
        if (!state.outgoingItem.CurrentAddress.Plz) {
            valid = false
            errors.push("Applicant Postcode")
        }
        if (!state.outgoingItem.ECFirstName) {
            valid = false
            errors.push("Emergency Contact First Name")
        }
        if (!state.outgoingItem.ECLastName) {
            valid = false
            errors.push("Emergency Contact Last Name")
        }
        if (!state.outgoingItem.ECPhone) {
            valid = false
            errors.push("Emergency Contact Phone Number")
        }
        if (!state.outgoingItem.MotherTongueID) {
            valid = false
            errors.push("Mothertongue")
        }
        if (state.outgoingItem.Universities.length === 0 || state.outgoingItem.Universities.find(u => u.Preference === 1) === undefined) {
            valid = false
            errors.push("Preferred Partner University")
        }
        if (state.outgoingItem.Motivation == null || state.outgoingItem.Motivation.length < 1500) {
            valid = false
            errors.push("Motivation essay too short")
        } else if (state.outgoingItem.Motivation.length > 4000) {
            valid = false
            errors.push("Motivation essay too long (you are obviously motivated enough, though)")
        }
        if (state.outgoingItem.OtherLanguages.length > 0 && state.outgoingItem.OtherLanguages.find(u => u.Certificate && u.Certificate.FileName != null && u.Certificate.FileName !== "" && !u.Certificate.FileName.toLowerCase().endsWith("pdf")) !== undefined) {
            valid = false
            errors.push("Only pdf file is allowed for Language Certificate")
        }
        if (!state.outgoingItem.CV || state.outgoingItem.CV.FileName === "") {
            valid = false
            errors.push("Curriculum Vitae not uploaded")
        }
        else {
            if (state.outgoingItem.CV.FileName != null && state.outgoingItem.CV.FileName !== "" && !state.outgoingItem.CV.FileName.toLowerCase().endsWith("pdf")) {
                valid = false
                errors.push("Only pdf file is allowed for Curriculum Vitae")
            }
        }
        dispatch({ type: "setValidationErrors", payload: errors })
        return valid
    }, [state.outgoingItem.CV, state.outgoingItem.CurrentAddress.Ort, state.outgoingItem.CurrentAddress.nationID,
    state.outgoingItem.CurrentAddress.Plz, state.outgoingItem.CurrentAddress.Strasse, state.outgoingItem.ECFirstName,
    state.outgoingItem.ECLastName, state.outgoingItem.ECPhone, state.outgoingItem.MotherTongueID,
    state.outgoingItem.Motivation, state.outgoingItem.PassportNumber, state.outgoingItem.PhoneNumber, state.outgoingItem.Universities, state.outgoingItem.OtherLanguages])

    const isValidSave = useCallback((): boolean => {
        let valid = true
        let errors = []

        if (state.outgoingItem.Motivation.length > 4000) {
            valid = false
            errors.push("Motivation essay too long (you are obviously motivated enough, though)")
        }
        if (state.outgoingItem.CV && state.outgoingItem.CV.FileName !== "") {
            if (state.outgoingItem.CV.FileName != null && state.outgoingItem.CV.FileName !== "" && !state.outgoingItem.CV.FileName.toLowerCase().endsWith("pdf")) {
                valid = false
                errors.push("Only pdf file is allowed for Curriculum Vitae")
            }
        }
        if (state.outgoingItem.OtherLanguages.length > 0 && state.outgoingItem.OtherLanguages.find(u => u.Certificate && u.Certificate.FileName != null && u.Certificate.FileName !== "" && !u.Certificate.FileName.toLowerCase().endsWith("pdf")) !== undefined) {
            valid = false
            errors.push("Only pdf file is allowed for Language Certificate")
        }
        dispatch({ type: "setValidationErrors", payload: errors })
        return valid
    }, [state.outgoingItem.CV, state.outgoingItem.Motivation, state.outgoingItem.OtherLanguages])

    const save = useCallback(async () => {
        // const accessToken = await getTokenRedirect(accountInfo)     
        if (!dsgvoChecked()) {
            toggleDsgvoDialogVisibility()
            return
        }
        if (!isValidSave()) {
            toggleValidationDialogVisibility()
            return
        }
        dispatch({ type: "setIsSaving", payload: true })
        const modifiedItem: OutgoingSaveRequest = {
            PassportNumber: state.outgoingItem.PassportNumber,
            PhoneNumber: state.outgoingItem.PhoneNumber,
            City: state.outgoingItem.CurrentAddress.Ort,
            Street: state.outgoingItem.CurrentAddress.Strasse,
            Plz: state.outgoingItem.CurrentAddress.Plz,
            NationID: state.outgoingItem.CurrentAddress.nationID,
            ECFirstName: state.outgoingItem.ECFirstName,
            ECLastName: state.outgoingItem.ECLastName,
            ECRelationship: state.outgoingItem.ECRelationship,
            ECEmail: state.outgoingItem.ECEmail,
            ECPhoneNumber: state.outgoingItem.ECPhone,
            ECStreet: state.outgoingItem.ECAddress.Strasse,
            ECCity: state.outgoingItem.ECAddress.Ort,
            ECPlz: state.outgoingItem.ECAddress.Plz,
            ECNationID: state.outgoingItem.ECAddress.nationID,
            Motivation: state.outgoingItem.Motivation,
            MotherTongueID: state.outgoingItem.MotherTongueID,
            OtherLanguages: state.outgoingItem.OtherLanguages,
            CV: {
                FileName: state.outgoingItem.CV?.FileName ?? "",
                FileType: state.outgoingItem.CV?.FileType ?? "",
                Content: state.outgoingItem.CV?.Content ?? ""
            },
            Universities: state.outgoingItem.Universities,
            allowForwardData: state.outgoingItem.allowForwardData,
            StudentGuid: state.outgoingItem.StudentGuid
        }

        const response = await updateOutgoingItem(oAuthToken,
            {
                ...modifiedItem,
            })
        dispatch({ type: "setIsSaving", payload: false })
        showSaveStatus(response === "200")
    }, [state.outgoingItem.PassportNumber, state.outgoingItem.PhoneNumber,
    state.outgoingItem.CurrentAddress.Ort, state.outgoingItem.CurrentAddress.Strasse,
    state.outgoingItem.CurrentAddress.Plz, state.outgoingItem.CurrentAddress.nationID, state.outgoingItem.ECFirstName,
    state.outgoingItem.ECLastName, state.outgoingItem.ECRelationship, state.outgoingItem.ECEmail,
    state.outgoingItem.ECPhone, state.outgoingItem.ECAddress.Strasse, state.outgoingItem.ECAddress.Ort,
    state.outgoingItem.ECAddress.Plz, state.outgoingItem.ECAddress.nationID, state.outgoingItem.Motivation,
    state.outgoingItem.MotherTongueID, state.outgoingItem.OtherLanguages, state.outgoingItem.CV, state.outgoingItem.Universities,
    state.outgoingItem.allowForwardData, state.outgoingItem.StudentGuid,
        oAuthToken, showSaveStatus, toggleDsgvoDialogVisibility, dsgvoChecked, isValidSave, toggleValidationDialogVisibility])

    const handleSubmit = useCallback(async () => {
        toggleSubmitDialogVisibility()
    }, [toggleSubmitDialogVisibility])

    const submit = useCallback(async () => {

        if (!dsgvoChecked()) {
            toggleDsgvoDialogVisibility()
            return
        }

        if (!isValid()) {
            toggleValidationDialogVisibility()
            return
        }
        dispatch({ type: "setIsSaving", payload: true })
        const modifiedItem: OutgoingSaveRequest = {
            PassportNumber: state.outgoingItem.PassportNumber,
            PhoneNumber: state.outgoingItem.PhoneNumber,
            City: state.outgoingItem.CurrentAddress.Ort,
            Street: state.outgoingItem.CurrentAddress.Strasse,
            Plz: state.outgoingItem.CurrentAddress.Plz,
            NationID: state.outgoingItem.CurrentAddress.nationID,
            ECFirstName: state.outgoingItem.ECFirstName,
            ECLastName: state.outgoingItem.ECLastName,
            ECRelationship: state.outgoingItem.ECRelationship,
            ECEmail: state.outgoingItem.ECEmail,
            ECPhoneNumber: state.outgoingItem.ECPhone,
            ECStreet: state.outgoingItem.ECAddress.Strasse,
            ECCity: state.outgoingItem.ECAddress.Ort,
            ECPlz: state.outgoingItem.ECAddress.Plz,
            ECNationID: state.outgoingItem.ECAddress.nationID,
            Motivation: state.outgoingItem.Motivation,
            MotherTongueID: state.outgoingItem.MotherTongueID,
            OtherLanguages: state.outgoingItem.OtherLanguages,
            CV: {
                FileName: state.outgoingItem.CV?.FileName ?? "",
                FileType: state.outgoingItem.CV?.FileType ?? "",
                Content: state.outgoingItem.CV?.Content ?? ""
            },
            Universities: state.outgoingItem.Universities,
            allowForwardData: state.outgoingItem.allowForwardData,
            StudentGuid: state.outgoingItem.StudentGuid
        }

        const response = await submitOutgoingItem(oAuthToken,
            {
                ...modifiedItem,
            })
        dispatch({ type: "setIsSaving", payload: false })
        if (response === "200") {
            dispatch({ type: "setSubmitted", payload: true })
            dispatch({ type: "setSubmittedJustNow", payload: true })
        }
        showSaveStatus(response === "200")
    }, [state.outgoingItem.PassportNumber, state.outgoingItem.PhoneNumber,
    state.outgoingItem.CurrentAddress.Ort, state.outgoingItem.CurrentAddress.Strasse,
    state.outgoingItem.CurrentAddress.Plz, state.outgoingItem.CurrentAddress.nationID, state.outgoingItem.ECFirstName,
    state.outgoingItem.ECLastName, state.outgoingItem.ECRelationship, state.outgoingItem.ECEmail,
    state.outgoingItem.ECPhone, state.outgoingItem.ECAddress.Strasse, state.outgoingItem.ECAddress.Ort,
    state.outgoingItem.ECAddress.Plz, state.outgoingItem.ECAddress.nationID, state.outgoingItem.Motivation,
    state.outgoingItem.MotherTongueID, state.outgoingItem.OtherLanguages, state.outgoingItem.CV, state.outgoingItem.Universities,
    state.outgoingItem.allowForwardData, state.outgoingItem.StudentGuid,
        oAuthToken, showSaveStatus, isValid, toggleValidationDialogVisibility, dsgvoChecked, toggleDsgvoDialogVisibility])

    if (!state.ready) return <LoadingAnimation />;
    if (!state.authorized) return <div className="full-width text-centered"><h1>Access denied</h1>{state.applicationDeadline && <div><h4>Unfortunately the application deadline is already over – late applications cannot be accepted.</h4><h4>Please contact the IMC International Relations Team (<a href="mailto:international@fh-krems.ac.at">international@fh-krems.ac.at</a>) for further information.</h4><h4>Application Deadline: {state.applicationDeadline}</h4></div>}</div>;
    if (state.submitted) return <div className="full-width text-centered">{state.submittedJustNow === true && <h1>Thank you for submitting your application</h1>}{state.submittedJustNow === false && <h1>Application already submitted</h1>}<h4>If you need to change your application details, please contact the IMC International Relations Team (<a href="mailto:international@fh-krems.ac.at">international@fh-krems.ac.at</a>).</h4><h4>Be aware that changes can only be accepted in exceptional cases!</h4></div>;
    return <div>
        <div id="header">
            <div id="banner" className="container">
                <a id="logo" title="Startseite" rel="noopener noreferrer" target="_blank" href="http://www.imc.ac.at"><img src="img/logo_IMC_UAS_Krems_quer_schwarz_PRINT.png" alt="IMC Fachhochschule Krems GesmbH - University of Applied Sciences Austria" /></a>
            </div>
        </div>
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>Student Application</h1>
                <p className="description">Academic Exchange Mobility {state.outgoingItem.BewerbungsSemester} ({state.outgoingItem.BewerbungsSemester.replace("WS", "Fall Term 20").replace("SS", "Spring Term 20")})</p>
                <div className="form-info">
                    <p className="discreet">You can save this form at any stage and come back to it later.</p>
                    <p className="discreet">Submitting is only permitted if all required fields (marked with <span className="red">*</span>) are filled out and documents are uploaded.</p>
                </div>
                <span></span>

                <form id="mainform" >
                    <fieldset>
                        <legend><h3>Applicant</h3></legend>
                        <h3 className="header headline">Personal Data</h3>
                        <div className="mt-1">
                            <table className="borderless full-width align-left">
                                <tbody>
                                    <tr>
                                        <th>First Name</th><td>{state.outgoingItem.FirstName}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Name</th><td>{state.outgoingItem.LastName}</td>
                                    </tr>
                                    <tr>
                                        <th>Gender</th><td>{state.outgoingItem.Gender === "W" ? "Female" : "Male"}</td>
                                    </tr>
                                    <tr>
                                        <th>Date of Birth</th><td>{state.outgoingItem.strDateOfBirth}</td>
                                    </tr>
                                    <tr>
                                        <th>Place of Birth</th><td>{state.outgoingItem.PlaceOfBirth} ({state.outgoingItem.CountryOfBirth})</td>
                                    </tr>
                                    <tr>
                                        <th>Nationality</th><td>{state.outgoingItem.Nationality}</td>
                                    </tr>
                                    <tr>
                                        <th>Student ID</th><td>{state.outgoingItem.StudentID}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th><td>{state.outgoingItem.Email}</td>
                                    </tr>
                                    <tr>
                                        <th>IMC Degree Programme</th><td>{state.outgoingItem.DegreeProgram}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ol className="form">
                            <li>
                                <label htmlFor="PhoneNumber"><span>Phone Number </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="PhoneNumber" id="phoneNumber" className={undefined} required={true} maxLength={30} value={state.outgoingItem.PhoneNumber} setText={(newText: string) => { dispatch({ type: "setPhoneNumber", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="PassportNumber"><span>Passport Number </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="PassportNumber" id="passPortNumber" className={undefined} required={true} maxLength={50} value={state.outgoingItem.PassportNumber} setText={(newText: string) => { dispatch({ type: "setPassportNumber", payload: newText }) }} />
                            </li>
                        </ol>
                        <h3 className="header headline wrapper-errors">Address</h3>
                        <ol className="form">
                            <li>
                                <label htmlFor="Strasse"><span>Street </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="Strasse" id="street" className={undefined} required={true} maxLength={250} value={state.outgoingItem.CurrentAddress.Strasse} setText={(newText: string) => { dispatch({ type: "setStrasse", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="country"><span>Country </span><em title="Required" className="fieldRequired">*</em></label>
                                <DropDownSelect data={nationList} className={"full-width countrySelector"} validityStyles={false} required={true} valueID={state.outgoingItem.CurrentAddress.nationID != null ? state.outgoingItem.CurrentAddress.nationID.toString() : "0"} valueChanged={(newId: string) => { dispatch({ type: "setNationID", payload: Number(newId) }) }} />
                            </li>
                            <li>
                                <label htmlFor="Plz"><span>Postcode </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="Plz" id="postcode" className={undefined} required={true} maxLength={20} value={state.outgoingItem.CurrentAddress.Plz} setText={(newText: string) => { dispatch({ type: "setPlz", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="Ort"><span>City </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="Ort" id="city" className={undefined} required={true} maxLength={250} value={state.outgoingItem.CurrentAddress.Ort} setText={(newText: string) => { dispatch({ type: "setOrt", payload: newText }) }} />
                            </li>
                        </ol>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Emergency Contact</h3></legend>
                        <h3 className="header headline">Contact Data</h3>
                        <ol className="form">
                            <li>
                                <label htmlFor="ECFirstName"><span>First Name </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="ECFirstName" id="ecFirstName" className={undefined} required={true} maxLength={50} value={state.outgoingItem.ECFirstName} setText={(newText: string) => { dispatch({ type: "setECFirstName", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="ECLastName"><span>Last Name </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="ECLastName" id="ecLastName" className={undefined} required={true} maxLength={150} value={state.outgoingItem.ECLastName} setText={(newText: string) => { dispatch({ type: "setECLastName", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="ECPhone"><span>Phone Number </span><em title="Required" className="fieldRequired">*</em></label>
                                <Textbox name="ECPhone" id="ecPhoneNumber" className={undefined} required={true} maxLength={50} value={state.outgoingItem.ECPhone} setText={(newText: string) => { dispatch({ type: "setECPhone", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="ECEmail"><span>Email </span></label>
                                <Textbox name="ECEmail" id="ecEmail" className={undefined} required={false} maxLength={250} value={state.outgoingItem.ECEmail} setText={(newText: string) => { dispatch({ type: "setECEmail", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="ECRelationship"><span>Relationship </span></label>
                                <Textbox name="ECRelationship" id="ecRelationship" className={undefined} required={false} maxLength={100} value={state.outgoingItem.ECRelationship} setText={(newText: string) => { dispatch({ type: "setECRelationship", payload: newText }) }} />
                            </li>
                        </ol>
                        <h3 className="header headline wrapper-errors">Address</h3>
                        <ol className="form">
                            <li>
                                <label htmlFor="ECStrasse"><span>Street </span></label>
                                <Textbox name="ECStrasse" id="ecStreet" className={undefined} required={false} maxLength={250} value={state.outgoingItem.ECAddress.Strasse} setText={(newText: string) => { dispatch({ type: "setECStrasse", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="ecCountry"><span>Country </span></label>
                                <DropDownSelect data={nationList} className={"full-width countrySelector"} validityStyles={false} required={false} valueID={state.outgoingItem.ECAddress.nationID != null ? state.outgoingItem.ECAddress.nationID.toString() : ""} valueChanged={(newId: string) => { dispatch({ type: "setECNationID", payload: Number(newId) }) }} />
                            </li>
                            <li>
                                <label htmlFor="ECPlz"><span>Postcode </span></label>
                                <Textbox name="ECPlz" id="ecPostcode" className={undefined} required={false} maxLength={20} value={state.outgoingItem.ECAddress.Plz} setText={(newText: string) => { dispatch({ type: "setECPlz", payload: newText }) }} />
                            </li>
                            <li>
                                <label htmlFor="ECOrt"><span>City </span></label>
                                <Textbox name="ECOrt" id="ecOrt" className={undefined} required={false} maxLength={250} value={state.outgoingItem.ECAddress.Ort} setText={(newText: string) => { dispatch({ type: "setECOrt", payload: newText }) }} />
                            </li>
                        </ol>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Language Competences</h3></legend>
                        <div>
                            <ParentContext.Provider value={{ state, dispatch }}>
                                <LanguageTable />
                            </ParentContext.Provider>
                        </div>
                        <div>
                            <SelfEvaluationTable />
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Curriculum Vitae in English<span className="red">*</span></h3></legend>
                        <div>
                            <p>Upload your CV as a PDF document. Other files types will be ignored!</p>
                        </div>
                        <div className="small">
                            <input id="cv" type="file" accept="application/pdf" onChange={(e) => cvChanged(e.target.files)} />
                            <div>{(!state.outgoingItem.CV) ?
                                "" : `Last uploaded: ${state.outgoingItem.CV.FileName}`}</div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Universities</h3></legend>
                        <div>
                            <p>Choose universities where you would like to spend your academic exchange semester mobility <b>(ranked according to preference).</b></p>
                            <p>Only partner universities with a bilateral agreement in your field of studies will be displayed below. You can check the availability on our <a href="https://www.imc.ac.at/en/international/studying-abroad/#c33169" target="_blank" rel="noopener noreferrer">website</a>. Please be aware that this list is subject to changes.</p>
                            <p>Selection of <u>one</u> university is mandatory – we recommend students of BA programmes to select 3 universities and students of MA programmes to select 2 universities.</p>
                        </div>
                        <div>
                            <ParentContext.Provider value={{ state, dispatch }}>
                                <UniversitiesTable />
                            </ParentContext.Provider>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend><h3>Motivation to Study Abroad<span className="red">*</span></h3></legend>
                        <div>
                            <p>Please briefly state your motivation to study abroad in general and specify your personal selection/ranking of partner universities. <b>(1500-4000 characters, including white space)</b></p>
                            <textarea id="motivation" className="full-width" rows={10} onChange={checkMotivation} defaultValue={state.outgoingItem.Motivation}></textarea>
                            <span>{motivationMessage}</span>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend><h3>General Data Protection Regulation<span className="red">*</span></h3></legend>
                        <div>
                            <p>For matters concerning exchange mobility, I authorise IMC Krems to pass on my full name and my IMC email contact and to publish them on eDesktop:</p>
                            <table className="borderless full-width align-left">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "80px" }}>
                                            <RadioButton
                                                id="radioForwardDataYes"
                                                name="radioForwardData"
                                                value={"1"}
                                                label={"Yes"}
                                                checked={state.outgoingItem.allowForwardData}
                                                setChecked={(newValue: boolean) => { dispatch({ type: "setAllowForwardData", payload: newValue }) }}
                                                setValue={(newValue: string) => { }} />
                                        </td>
                                        <td style={{ width: "10px" }}>
                                        </td>
                                        <td>
                                            <RadioButton
                                                id="radioForwardDataNo"
                                                name="radioForwardData"
                                                value={"0"}
                                                label={"No"}
                                                checked={!state.outgoingItem.allowForwardData}
                                                setChecked={(newValue: boolean) => { dispatch({ type: "setAllowForwardData", payload: newValue }) }}
                                                setValue={(newValue: string) => { }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div>
                            <p className="text-centered">Please confirm that you agree to the processing of your data (as specified in the following documents):</p>
                            <p className="text-centered">
                                <a href="DSGVO/DE_Information Outgoing Studierende.pdf" target="_blank" download>English</a>
                                &nbsp;|&nbsp;
                                <a href="DSGVO/DE_Information Outgoing Studierende.pdf" target="_blank" download>Deutsch</a>
                            </p>
                            <div id="newsletter-input">
                                <input
                                    type="checkbox"
                                    id="dsgvo"
                                    onChange={rememberDsgvo}
                                    defaultChecked={getCookie(getThisStudentsCookieName()) === "true"}
                                />
                                <label className="privacy2" htmlFor="dsgvo">By clicking this checkbox I confirm that I have read the documents linked above and agree to the processing of my data.</label>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div id="newsletter-input">
                                <input
                                    type="checkbox"
                                    id="checkstorno"
                                    onChange={ckStornoChanged}
                                    defaultChecked={false}
                                />
                                <label className="privacy2" htmlFor="checkstorno">I acknowledge that this registration is binding and a cancellation of the application or of the exchange mobility by the student is only possible if there is a justified reason. The existence of such a reason must be explained in writing to the IMC International Relations Department. Should no justified reason be apparent, a personal discussion between the student and the Head of IMC International Relations is required. Any costs arising from an unjustified cancellation may be charged to the student.</label>
                            </div>
                        </div>
                    </fieldset>
                    {state.isSaving
                        ? (<div style={{ height: '70px' }}> {/*Show loading icon while saving is done*/}
                            <div className="loading-holder">
                                <ReactLoading type="spin" color="rgba(47,72,102,1)" width={50} height={50} />
                            </div>
                        </div>)
                        : (<div style={{ height: '130px', marginTop: '20px' }}> {/*Show save & submit buttons*/}
                            <fieldset>
                                <ul className="form">
                                    <li><Button className="button blue left w-100" type="button" onClick={save}>&nbsp;Save</Button></li>
                                    <li><Button className="button orange right w-100" type="button" hidden={!state.submitVisible} primary={true} onClick={handleSubmit} icon="check">&nbsp;Submit</Button></li>
                                </ul>
                            </fieldset>
                        </div>)
                    }
                </form>
            </div>
        </div>
        {state.dsgvoDialogVisible && (<DsgvoDialog onClose={toggleDsgvoDialogVisibility} />)}
        {state.validationDialogVisible && (<ValidationDialog errors={state.validationErrors} onClose={toggleValidationDialogVisibility} />)}
        {state.submitDialogVisible && (<SubmitDialog onClose={toggleSubmitDialogVisibility} onSubmit={submit} />)}
        <div id="popup-panel" className="popup-panel" >
            <div id="popup-content" className="alert alert-success full-width text-centered"></div>
        </div>
        <div id="portal-footer" className="row">
            <div id="portal-footer-content" className="container clearfix">
                <hr />
                <dl className="g160 " id="more-websites">
                    <dt><span id="footercontrol_Label_more_website">More Information</span></dt>
                    <dd id="footercontrol_websites_oeh"><a href="https://imcfhkrems.sharepoint.com/sites/IR-Online/SitePages/Study-abroad!.aspx" id="footercontrol_a_websites_oeh" title="​​​Information for Outgoing Exchange Students" className="external-link" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_websites_oeh">Study abroad</span></a></dd>
                    <dd id="footercontrol_websites_stik"><a href="https://imcfhkrems.sharepoint.com/sites/IR-Online/SitePages/Erasmus+-for-Studies-Abroad.aspx?csf=1&web=1&e=fkIb5F" id="footercontrol_a_websites_stik" title="Erasmus+ Grant for Studies Abroad" className="external-link" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_websites_stik">Erasmus+ for studies abroad</span></a></dd>
                </dl>

                <div className="vcard g240">

                    <dl className="adr" id="contact">
                        <dt><span id="footercontrol_label_Kontakt_H">Contact</span></dt>
                        <dd className="fn org organization-name"><abbr className="geo" title="48.412863;15.6002"><span><span id="footercontrol_FH_Label">IMC Krems GesmbH</span></span></abbr></dd>
                        <dd className="street-address visuallyhidden">Piaristengasse 1</dd>
                        <dd><span className="postal-code">3500</span> <span className="locality">Krems</span> - <span className="country-name"><span id="footercontrol_label_country">Österreich</span></span> - <span className="region"><span id="footercontrol_label_region">Europa</span></span></dd>
                        <dd>T: <abbr className="type visuallyhidden" title="work pref">Work</abbr><span className="value">+43 2732 802 511</span></dd>
                        <dd>E: <a className="value" href="mailto:international@imc.ac.at" rel="noopener noreferrer" target="_blank">international@imc.ac.at</a></dd>
                        <dd className="visuallyhidden">I: <a href="http://www.imc.ac.at/" className="url" rel="noopener noreferrer">http://www.imc.ac.at/</a></dd>
                    </dl>
                </div>

                <dl className="g160 " id="help-services">
                    <dt><span id="footercontrol_label_help_services">Help and Services</span></dt>
                    <dd id="service-contact">
                        <a href="https://imcfhkrems.sharepoint.com/sites/ir-online" id="footercontrol_a_contact" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_kontakt">Contact</span></a>
                    </dd>

                    <dd id="service-imprint">
                        <a href="https://www.imc.ac.at/en/imprint/" id="footercontrol_a_imprint" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_imprint">Credits</span></a>
                    </dd>
                    <dd id="service-legality">
                        <a href="https://www.imc.ac.at/en/data-protection/" id="footercontrol_a_legality" rel="noopener noreferrer" target="_blank"><span id="footercontrol_label_legality">Legal notice</span></a>
                    </dd>
                    <dd id="service-eu_language">
                        <a href="https://rm.coe.int/CoERMPublicCommonSearchServices/DisplayDCTMContent?documentId=090000168045bb52" id="footercontrol_a_eu_language" rel="noopener noreferrer" target="_blank">
                            <span id="footercontrol_label_eu_language">Common European Framework of Reference for Languages (CEFR)</span>
                        </a>
                    </dd>
                </dl>
            </div>
        </div>
    </div>

}
export default Registration